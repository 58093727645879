import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmPasswordValidator } from '../confirm.password.validator';
import { AuthenticationService } from '../../../SIDERPERU/services/Authentication/_services';
import { AuthNoticeService } from '../../../../../core/auth';
import { Subject } from 'rxjs';

@Component({
  selector: 'kt-reset-confirm-password',
  templateUrl: './reset-confirm-password.component.html',
  styleUrls: ['./reset-confirm-password.component.scss']
})
export class ResetConfirmPasswordComponent implements OnInit {

  hide = false
  hideconfirm = false
  loading = false;

  resetPasswordForm : FormGroup
  aux_tok : any
  reset_pass = {
    id: '',
    username: ''
  }

  private unsubscribe: Subject<any>;

  constructor(
    private activateRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private authNoticeService: AuthNoticeService,
    private cdr: ChangeDetectorRef,
  ) { 

    this.unsubscribe = new Subject();

    this.aux_tok = {
      token: this.activateRoute.snapshot.params.token
    }

    this.resetPasswordForm = this.fb.group({
			password: ['', [Validators.required]],
			confirmpass: ['', [Validators.required]],
		}, { validator: ConfirmPasswordValidator.MatchPassword }
		);
  }

  ngOnInit() {
    this.ongetValidateToken()
  }

  ongetValidateToken(){
    this.authenticationService.passToReset(this.aux_tok).subscribe(data => {
      if(data.response == 'success'){
        this.reset_pass = {
          id: data.data.id,
          username: data.data.email
        }
      }else{
        this.authNoticeService.setNotice('Ocurrió un problema, intente de nuevo o vuelva a enviar su solicitud', 'danger');
        this.loading = false;
        this.cdr.detectChanges()
      }
    },
    error => {
      this.authNoticeService.setNotice('Se ha perdido la conexión con los servidores y/o servicios. Intente nuevamente. Contacte a su soporte primer nivel del área de Tecnología de la Información.', 'danger');
      this.loading = false;
      this.cdr.detectChanges()
    })
  }

  submit(){
    this.authNoticeService.setNotice(null);
    if (!this.resetPasswordForm.valid) {
      return
    }

    this.loading = true 

    let send = {
      id: this.reset_pass.id,
      username: this.reset_pass.username,
      password: this.resetPasswordForm.value.password
    }
    
    this.authenticationService.sendResetPass(send).subscribe(data => {
      if(data.response == 'success'){
        this.router.navigate(['/']);
      }else{
        this.authNoticeService.setNotice('Ocurrió un problema, intente de nuevo o vuelva a enviar su solicitud', 'danger');
      }
      this.loading = false;
      this.cdr.detectChanges()
    },
    error => {
      this.authNoticeService.setNotice('Se ha perdido la conexión con los servidores y/o servicios. Intente nuevamente. Contacte a su soporte primer nivel del área de Tecnología de la Información.', 'danger');
      this.loading = false;
      this.cdr.detectChanges()
    })
  }

  /**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.resetPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
  }
  
  ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}
}
