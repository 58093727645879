import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  //private domain: string ="https://34.216.148.9:8080/"
  //private domain: string ="https://34.216.148.9:443/"
  //private domain: string = "https://52.116.35.77:443/"
  //private domain: string = "https://52.116.35.78:443/"

  //private domain: string = "https://127.0.0.1:8000/"
  //private domain: string = "https://0.0.0.0:443/"


  private domain: string = "https://10.140.32.124:443/"


  constructor() { }

  getDomain(): string {
    return environment.domain
  }

  getDomainGPS(): string{
    return environment.domainGPS
  }
}
