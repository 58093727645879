import { Router } from '@angular/router';
import { Route } from './../../../../models/masterGeneral';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, window } from 'rxjs/operators';

import { User } from '../_models';
import { DomainService } from '../../domain.service';
import { WindowsTimesComponent } from '../../../components/configuration/var-centro/config-warehouse/windows-times/windows-times.component';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  permisosTMP :{};

  constructor(private http: HttpClient,
    private dom: DomainService,
    private route: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  Permisos(username: string, password: string) {
    const url = this.dom.getDomain() + 'api/accounts/permissions/'
    return this.http.get<any>(url)
      .pipe(map(permisos => {
        this.permisosTMP={
          'code':permisos.code,
          'firstname':permisos.firstname,
          'username':permisos.username,
          "content_types":permisos.content_types,
          //'groups':[{'code': permisos.groups[0].code}]
          'groups':[{'code': permisos.groups[0].code}]
        }
        console.log("XYZ",this.permisosTMP)

        localStorage.setItem('Permisos', JSON.stringify(this.permisosTMP));


        console.log("Llamada",JSON.stringify(permisos));
            return permisos;
    
          }));
        }
      

  public getCurrentUser() {
    return JSON.parse(localStorage.getItem('currentUser'))
  }

  public getCurrentUserToken() {
    return JSON.parse(localStorage.getItem('currentUser')).token
  }

  login(username: string, password: string) {
    const url = this.dom.getDomain() + 'api-token-auth/'
    return this.http.post<any>(url, { username, password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }
  saveLoginInfo(send:any) {
    const url = this.dom.getDomain() + 'api/main/save_login_info/'
    
    return this.http.post<any>(url, { send })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //localStorage.setItem('currentUser', JSON.stringify(user));
        //this.currentUserSubject.next(user);
        return user;
        
      }));
  }
  loginSider(username: string, password: string) {
    const url = this.dom.getDomain() + 'api/accounts/microsoft/'+username+'/'
    return this.http.get<any>(url)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('currentUser', JSON.stringify(user));
        // this.currentUserSubject.next(user);
        return user;
      }));
  }

  accountValidate(data){
    const url = this.dom.getDomain() + 'api/accounts/validate_ad/'
    return this.http.post<any>(url, data)
  }

  resetPassEmail(data){
    const url = this.dom.getDomain() + 'api/accounts/reset/mail/'
    return this.http.post<any>(url, data)
  }

  passToReset(data){
    const url = this.dom.getDomain() + 'api/accounts/token/pass/'
    return this.http.post<any>(url, data)
  }

  sendResetPass(data){
    const url = this.dom.getDomain() + 'api/accounts/reset/password/login/'
    return this.http.post<any>(url, data)
  }

  resetPassword(email: string, password: string) {
    const url = this.dom.getDomain() + 'api/accounts/reset_password/'+email+'/'+password
    return this.http.post<any>(url, { email, password })
  }

  logout() {
    // remove user from local storage to log user out 
    localStorage.removeItem('currentUser');
    //this.route.navigate(['auth/login'])
    localStorage.removeItem('Permisos');
    localStorage.removeItem('PermisosInternos');
    this.currentUserSubject.next(null);
    location.reload();
  }
}
