import { Injectable } from "@angular/core";
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	CanActivateChild,
} from "@angular/router";

import { AuthenticationService } from "../_services";

import { ErrorManagementService } from '../../../../SIDERPERU/services/error-management.service';

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate, CanActivateChild {
	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private errorService:ErrorManagementService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		var permisos = JSON.parse(localStorage.getItem("Permisos"));
		let sider='';
		try{
			sider = localStorage.getItem("sider");
		}
		catch (error) {
			sider='false';
		}
		
		if(sider=='true'){
			this.router.navigate(["auth/login"]);
			return false;
		}

		if (permisos) {
			let perA = this.ValidPermison(state.url);
			localStorage.setItem("PermisosInternos", JSON.stringify(perA));
		}

		const currentUser = JSON.parse(
			JSON.stringify(this.authenticationService.currentUserValue)
		);
		//console.log('USUARIO',currentUser.user)

		var nombre = state.url.split("/");
		var n = nombre[nombre.length - 1];
		var flag = false;
		var c = 0;
		console.log("Nombre_split", n.toLocaleUpperCase());
		if (currentUser) {
			if (currentUser.user == "admin" || currentUser.user == "sideradmin") {
				flag = true;
			} else {
				if (permisos.content_types) {
					console.log("Jarol1")
					permisos.content_types.forEach((element) => {
					
						console.log("QQ2",state.url.toLocaleUpperCase());
						console.log("QQ3",element.app_label.substring(7, element.app_label.length - 2).toLocaleUpperCase());
						if (
							//Ultima cambio Jarol
							state.url.toLocaleUpperCase().includes(element.app_label.substring(7, element.app_label.length - 2)
							.toLocaleUpperCase())

					
						) {
							console.log("Nombre COn", element.model);
							if (c == 0) {
								console.log("ENTROO");
								flag = true;
							}
						}
					});
				}
			}

			if (nombre[nombre.length - 2] == "default") {
				flag = true;
			}

			return flag;

			// logged in so return true Jarol
		}

		// not logged in so redirect to login page with the return url
		this.router.navigate(["auth/login"]);
		return false;
	}
	ValidPermison(per: any) {
		console.log("EntrarValidPer")
		var arr = [];
		var r = "";

		var permisos = JSON.parse(localStorage.getItem("Permisos"));
		var flag = false;
		console.log("PERMISOS", permisos);

		if (permisos.content_types && permisos) {
			console.log("ENTRO AL VALID");
			permisos.content_types.forEach((element) => {
				r = element.app_label.toString();
				r = r.substring(7, r.length - 2);

				console.log("per", per);
				console.log("r", r);
				if (per.includes(r)) {
					console.log("app_label", r);

					element.permissions.forEach((ele) => {
						arr.push(ele.code);
					});
				}
			});
		}

		return arr;
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) {
		console.log("RUTAZACLID", route);
		console.log("RUTAZA2CLID", state);
		const currentUser = this.authenticationService.currentUserValue;
		if (currentUser) {
			// logged in so return true
			return true;
		}

		// not logged in so redirect to login page with the return url
		this.router.navigate(["auth/login"], {
			queryParams: { returnUrl: state.url },
		});
		return false;
	}
}
