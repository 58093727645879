import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorManagementService {

  constructor(
    private toastr: ToastrService
  ) { }

  getErrorMessage(error) {
    if (!navigator.onLine) {
      this.toastr.error('No hay conexión a internet')
    }
    else if (error instanceof ErrorEvent) {
      // client-side error
      this.getClientMessage(error)
    } else {
      // backend error
      this.getServerMessage(error)
    }
  }

  private getClientMessage(error) {
    if (error.error == undefined) {
      this.toastr.error('Error: ' + error)
    } else {
      this.toastr.error('Error del cliente: ' + error.error.message ? error.error.message : error.toString());
    }
  }
  private getServerMessage(error: HttpErrorResponse) {


    if (error.status == undefined) {

      this.toastr.error(error.error)
    } else {
      let err = ''
      for (let k in error.error) {
        err = err + k + ': ' + error.error[k] + ' '
      }
      this.toastr.error(err);
    }
  }
}
