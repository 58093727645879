import { DomainService } from '../../../services/domain.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ConfigurationuserService {

    constructor(public http: HttpClient, private dom: DomainService) { }

    headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
    })

    getCnfUsers() {
        const url_api = this.dom.getDomain() + 'api/cnf_user/'
        return this.http.get(url_api, { headers: this.headers });
    }
    getListUsersCode(data:any) {
        const url_api = this.dom.getDomain() + 'api/list_user_code/'+data+'/'
        return this.http.get(url_api, { headers: this.headers });
    }
    getListUsers() {
        const url_api = this.dom.getDomain() + 'api/list_user/'
        let data:any;
        return this.http.post(url_api, data, { headers: this.headers });
    }

    createCnfUsers(data: any) {
        const url_api = this.dom.getDomain() + 'api/cnf_user/'
        return this.http.post(url_api, data, { headers: this.headers });
    }

    updateCnfUsers(code: string, data: any) {
        const url_api = this.dom.getDomain() + `api/cnf_user/${code}/`
        return this.http.put(url_api, data, { headers: this.headers });
    }

    deleteCnfUsers(code: string) {
        const url_api = this.dom.getDomain() + `api/cnf_user/${code}/`
        return this.http.delete(url_api, { headers: this.headers });
    }

    // paginator
    getCenterTypeByUrl(url) {
        const url_api = url
        return this.http.get(url_api, { headers: this.headers });
    }
}