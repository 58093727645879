import { DomainService } from '../../../services/domain.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  //para la vista de modulos
  private currentConfigModuleSubject: BehaviorSubject<any>;
  public currentConfigModule: Observable<any>;

  constructor(private http: HttpClient, private dom: DomainService) {
    this.currentConfigModuleSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentConfigModule')));
    this.currentConfigModule = this.currentConfigModuleSubject.asObservable();
  }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
    //Authorization: this.authService.getToken()
  })

  urltum: string = this.dom.getDomain() + 'api/'


  getLogs(data: any) {
    const url_api = this.urltum + 'main/logs/'
    return this.http.post(url_api, data, { headers: this.headers });
  }

  getConfCorreos() {
    const url_api = this.urltum + 'configuration/type_mail/'
    return this.http.get(url_api, { headers: this.headers });
  }

  saveConfCorreos(data) {
    const url_api = this.urltum + 'configuration/mail/'
    return this.http.post(url_api, data, { headers: this.headers });
  }

  restoreConfCorreo(code) {
    const url_api = this.urltum + 'configuration/restore/mail/' + code + '/'
    return this.http.get(url_api, { headers: this.headers });
  }


  // Varibles globales centro
  varGlobalesGet() {
    const url_api = this.urltum + 'configuration/configuration/'
    return this.http.get(url_api, { headers: this.headers });
  }

  varGlobalesPost(data) {
    const url_api = this.urltum + 'configuration/configuration/'
    return this.http.post(url_api, data, { headers: this.headers });
  }

  varGlobalesPut(id, data) {
    const url_api = this.urltum + 'configuration/configuration/' + id + '/'
    return this.http.put(url_api, data, { headers: this.headers });
  }

  // Vraibles por centro


  varCentroGet(code) {
    const url_api = this.urltum + 'configuration/center/capacity/filter/' + code + '/'
    return this.http.get(url_api, { headers: this.headers });
  }

  varCentroDelete(code) {
    const url_api = this.urltum + 'configuration/center/capacity/' + code + '/'
    return this.http.delete(url_api, { headers: this.headers });
  }



  varCentroPost(data) {
    const url_api = this.urltum + 'configuration/center/capacity/'
    return this.http.post(url_api, data, { headers: this.headers });
  }



  typeVehicleGet() {
    const url_api = this.urltum + 'main/cb/vehicle_type/'
    return this.http.get(url_api, { headers: this.headers });
  }

  typeVehicleGetConfigurationCenter(code) {
    const url_api = this.urltum + 'configuration/cb/vehicle_type/' + code + '/'
    return this.http.get(url_api, { headers: this.headers });
  }

  /// configuracion de modulo

  getConfigurationModulo(id) {
    const url_api = this.urltum + 'configuration/configuration_module/' + id + '/'
    return this.http.get(url_api, { headers: this.headers }).pipe(map(config => {
      // store config details and jwt token in local storage to keep config logged in between page refreshes
      localStorage.setItem('currentConfigModule', JSON.stringify(config));
      this.currentConfigModuleSubject.next(config);
      return config;
    }));;
  }

  updateConfigurationModulo(id, data) {
    const url_api = this.urltum + 'configuration/configuration_module/' + id + '/'
    return this.http.put(url_api, data, { headers: this.headers });
  }


}