// Spain
export const locale = {
	lang: 'es',
	data: {
		TRANSLATOR: {
			SELECT: 'Elige tu idioma',
		},
		MENU: {
			NEW: 'nuevo',
			ACTIONS: 'Comportamiento',
			CREATE_POST: 'Crear nueva publicación',
			PAGES: 'Pages',
			FEATURES: 'Caracteristicas',
			APPS: 'Aplicaciones',
			DASHBOARD: 'Tablero'
		},
		AUTH: {
			GENERAL: {
				OR: 'O',
				SUBMIT_BUTTON: 'Enviar',
				NO_ACCOUNT: 'No tienes una cuenta?',
				SIGNUP_BUTTON: 'Regístrate',
				FORGOT_BUTTON: 'Se te olvidó tu contraseña',
				BACK_BUTTON: 'Atrás',
				PRIVACY: 'Intimidad',
				LEGAL: 'Legal',
				CONTACT: 'Contacto',
			},
			LOGIN: {
				TITLE: 'Crear una cuenta',
				BUTTON: 'Registrarse',
			},
			FORGOT: {
				TITLE: 'Contraseña olvidada?',
				DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
				SUCCESS: 'Your account has been successfully reset.'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.'
			},
			INPUT: {
				EMAIL: 'Correo electrónico',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'Usuario'
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'Los datos de inicio de sesión son incorrectos.',
				REQUIRED_FIELD: 'Campo requerido',
				MIN_LENGTH_FIELD: 'El campo de tener un mínimo de:',
				MAX_LENGTH_FIELD: 'El campo de tener un máximo de:',
				INVALID_FIELD: 'El campo no es válido',
			}
		},
		VALIDATION: {
			PASSWORD_NOTMATCH: 'Las contraseñas ingresadas no coinciden.',
			INVALID: 'El campo {{name}} no es válido.',
			REQUIRED: 'El campo {{name}} es requerido.',
			MIN_LENGTH: 'El campo {{name}} debe tener como mínimo {{min}} carácteres.',
			AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
			NOT_FOUND: 'La solicitud {{name}} no tuvo respuesta.',
			INVALID_LOGIN: 'Los datos de inicio de sesión son incorrectos.',
			REQUIRED_FIELD: 'Campo requerido.',
			MIN_LENGTH_FIELD: 'El campo de tener un mínimo de:',
			MAX_LENGTH_FIELD: 'El campo de tener un máximo de:',
			INVALID_FIELD: 'El campo no es válido.',
			OPTIONAL: 'Campo opcional',
			MONEY: "La moneda no ha sido configurada.",
			DISTANCE: "La distancia no ha sido configurada.",
			GEOPOSITION_ORIGIN: "La latitud y loongitud no ha sido configurada, en el origen.",
			GEOPOSITION_DESTINATION: "La latitud y loongitud no ha sido configurada, en el origen.",
		},
		STATUS: {
			ACTIVE: "Activo",
			INACTIVE: "Inactivo",
			ASSIGNED: "Asignado",
			AVAILABLE: "Disponible",
			TO_FINALIZE: "Por Finalizar",
		},
		FORM: {
			TITLE: "",
			NEW: "Nuevo Registro",
			EDIT: "Editar Registro",
			DELETE: "Eliminar Registro",
			DELETE_INFO: "¿Estás seguro de eliminar el registro?",
			ACTIONS: "Acciones",
			SORTER: "Ordenar Registo",
			TO_IMPORT: "Importar",
			TO_EXPORT: "Exportar",
			TO_IMPORT_TITLE: "Importar datos desde un archivo",
			SAVE: "Guardar",
			ACCEPT: "Aceptar",
			CANCEL: "Cancelar",
			CLOSE: "Cerrar",
			OPENING_HOUR: "Horario(s) de Atención",
			OWNER: "Nuevo Propietario",
			TIME_ATTENTION: 'Horario de Atención',
			GATE: 'Puertas',
		},
		MESSAGE: {
			UNDEFINED: 'No Definido',
			ZERO_RECORDS: 'No se encontraron resultados.',
			IMPORT: "Los registros se agregarón con éxito.",
			IMPORT_VALIDATE: "El archivo a importar, se encuentra vacío.",
			IMPORT_INCOMPLETE: "El archivo contiene campos sin completar.",
			IMPORT_NO: "El campo menor es mayor que el máximo.",
			AMOUNT_MIN_MAX: "El monto mínimo no puede ser mayor que el monto máximo.",
			DELETE: "El registro se eliminó con éxito.",
			NEW: "El registro se agrego con éxito.",
			EDIT: "El registro se edito con éxito.",
		},
		PAGINATOR: {
			ITEMS_PER_PAGE_LABEL: "Počet řádků:",
			NEXT_PAGE_LABEL: "Další stránka",
			PREVIOUS_PAGE_LABEL: "Předchozí stránka",
			FIRST_PAGE_LABEL: "První stránka",
			LAST_PAGE_LABEL: "Poslední stránka",
			RANGE_PAGE_LABEL_1: "0 z {{length}}",
			RANGE_PAGE_LABEL_2: "{{startIndex}} - {{endIndex}} z {{length}}"
		},
		INPUT: {
			STATUS: 'Estado',
			CAPTION: 'Leyenda',
			ESTIMATED_TIME: 'TIEMPO ESTIMADO DE:',
			ESTIMATED_STAY_INSIDE_CENTER: 'Permanencia en el Centro',
			ESTIMATED_ARRIVAL_WAREHOUSE: 'Arrivo Almacén',
			ESTIMATED_STAY_INSIDE_WAREHOUSE: 'Permanencia en el Almacén',
			HOUR: 'HORAS',
			MINUTE: 'MINUTOS',
			SEARCH: 'Buscar...',
			SEARCH_INITIAL: 'Buscar',
			SEARCH_ADDRESS: 'Buscar dirección...',
			FILTER: 'Ejecutar filtros',
			FILTER_RESTART: 'Reiniciar filtros',
			FILTER_SHOW: 'Mostrar filtros',
			FILTER_HIDE: 'Ocultar filtros',
			EMAIL: 'Correo Electrónico',
			FULLNAME: 'Nombres Completos',
			PASSWORD: 'Contraseña',
			CONFIRM_PASSWORD: 'Confirmar Contraseña',
			USERNAME: 'Usuario',
			CODE: 'Código',
			CODE_ERP: 'Código ERP',
			DESCRIPTION: 'Descripción',
			ID: 'ID',
			NAME: 'Nombre',
			COMPANY_NAME: 'Nombre de la Compañia',
			COMPANY_ID: 'RUC',
			COMPANY: 'Nombre de la Empresa',
			AGENT_SNAME: 'Apellido(s) del Representante',
			AGENT_LNAME: 'Nombre(s) del Representante',
			ADDRESS: 'Dirección',
			PHONE: 'Teléfono',
			MOBIL: 'Celular',
			WEB: 'Página Web',
			CENTER: 'Centro',
			CENTER_TYPE: 'Tipo de Centro',
			OWNER: 'Propietario',
			CARRIER: 'Proveedor',
			DRIVER: 'Conductor',
			LICENSE: 'Licencia',
			VEHICLE_TYPE: 'Tipo de vehículo',
			VEHICLE_TRACT: 'Tracto',
			VEHICLE_TUMBRIL: 'Carreta',
			VEHICLE_AVAILABLE: 'Disponible',
			VEHICLE_QUANTITY: 'Cnt. Vehículos',
			TRANSPORT_AGREEMENT: 'Tipo de Flete',
			MONEY: 'Moneda',
			ORIGIN: "Origen",
			DESTINATION: "Destino",
			FREIGHT: "Flete",
			FREIGHT_PRINCIPAL: "Flete Principal",
			FREIGHT_ADITIONAL: "Flete Adicional",
			DISTANCE: "Distancia",
			LOCATION: "Ubicación",
			AMOUNT_MIN: "Monto Mínimo",
			AMOUNT_MAX: "Monto Máximo",
			TYPE: "Tipo",
			EVENT: "Evento",
			LATITUDE: "Latitud",
			LONGITUDE: "Longitud",
			WAREHOUSE: 'Almacén',
			GATE: 'Puerta',
			TIME_ATTENTION: 'Horario de Atención',
			ROUTE: 'Ruta',
			DELIVERY_DATE: 'Fecha de Entrega',
			TOTAL: 'Total',
			TOTAL_PAID: 'Total Pagado',
			TOTAL_RECEIVABLE: 'Total por Cobrar'


		},
		DATES: {
			FROM: 'Desde',
			TO: 'Hasta',
			START_DATE: 'Fecha de inicio',
			END_DATE: 'Fecha de fin',
			START_HOUR: 'Hora de inicio',
			END_HOUR: 'Hora de fin',
			DELIVERY_TIME: 'T. Entrega',
			HOUR: 'Hora(s)',
			DAY: 'Día(s)'
		},
		SELECT: {
			ALL: "TODOS",
			ZERO_RECORDS: 'Sin Resultados',
			PROFILE: 'Seleccione un Perfil',
			COUNTRY: 'Seleccione un País',
			LOCATION_ONE: 'Seleccione un Departamento',
			LOCATION_TWO: 'Seleccione una Ciudad',
			LOCATION_THREE: 'Seleccione una Distrito',
			CENTER: 'Seleccione un Centro',
			CENTER_TYPE: 'Seleccione un Tipo de Centro',
			CARRIER: 'Seleccione Proveedor de Transporte',
			VEHICLE_TYPE: 'Seleccione Tipo de Vehículo',
			VEHICLE_TRAC: 'Seleccione Tracto',
			VEHICLE_TUMBRIL: 'Seleccione Carreta',
			DRIVER: 'Seleccione Conductor',
			TRANSPORT_AGREEMENT: 'Seleccione un Tipo de Flete',
			TYPE: 'Seleccione un Tipo',
			MONEY: 'Seleccione una Moneda',
			OWNER: 'Seleccione un Propietario',
			ORIGIN: 'Seleccione un Origen',
			DESTINATION: 'Seleccione un Destino',

		},
		OPTION: {
			PROFILE_OPTION_1: 'PROVEEDOR DE TRANSPORTE',
			PROFILE_OPTION_2: 'CLIENTE GENERADOR DE CARGA',
			PROFILE_OPTION_3: 'CENTRO DE ABASTECIMIENTO',
			FREIGHT_WEIGHT: 'PESO',
			FREIGHT_VOLUME: 'VOLUMEN',
			FREIGHT_DISTANCE: 'DISTANCIA',
			DOCUMENT: '¿Requiere Adjuntar Documento?',
		}
	}
};
