// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
import { AuthNoticeService } from '../../../../core/auth';
import { ErrorManagementService } from '../../SIDERPERU/services/error-management.service';
import { AuthenticationService } from '../../SIDERPERU/services/Authentication/_services';
import { ConfigurationuserService } from '../../SIDERPERU/services/Configuration/configuration.user.service';
import { ConfirmPasswordValidator } from './confirm.password.validator';
// Auth

@Component({
	selector: 'kt-forgot-password',
	templateUrl: './forgot-password.component.html',
	encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	// Public params
	forgotPasswordForm: FormGroup;
	loading = false;
	errors: any = [];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(

		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private CnfUserService: ConfigurationuserService, //users
		private authenticationService: AuthenticationService,
		private errorService: ErrorManagementService,
		private authNoticeService: AuthNoticeService,
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegistrationForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		this.forgotPasswordForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			]}
		);
	}

	/**
	 * Form Submit
	 */
	submit() {

		this.loading = true;

		let send = {
			username: this.forgotPasswordForm.value.email
		}
		
		this.authenticationService.accountValidate(send).subscribe(data => {
			if(data.response == 'success'){
				if(data.data.active_directory == true){
					this.authNoticeService.setNotice('Estimado usuario, el cambio de contraseña no esta disponible para su cuenta ya que pertenece al Active Directory de su organización.', 'danger');	
				}else{

					let email = {
						email: data.data.user
					}
					this.authenticationService.resetPassEmail(email).subscribe( element =>{
						if(element.response == 'success'){
							this.authNoticeService.setNotice('Estimado usuario, recibirás instrucciones en tu correo electrónico para cambiar la contraseña', 'info');
						}else{
							this.authNoticeService.setNotice('Estimado usuario, ha habido un problema al enviar tu solicitud a este correo.', 'danger');
						}
						this.loading = false;
						this.cdr.detectChanges()
					},
					error => {
						this.loading = false;
						this.cdr.detectChanges()
						this.authNoticeService.setNotice('Se ha perdido la conexión con los servidores y/o servicios. Intente nuevamente. Contacte a su soporte primer nivel del área de Tecnología de la Información.', 'danger');
						//this.errorService.getErrorMessage(error)
					})
				}
				this.loading = false;
				this.cdr.detectChanges()
			}else if(data.response == 'error'){
				this.authNoticeService.setNotice('Correo electrónico no existe', 'danger');
				this.loading = false;
				this.cdr.detectChanges()
			}
		},
		error => {
			this.authNoticeService.setNotice('Se ha perdido la conexión con los servidores y/o servicios. Intente nuevamente. Contacte a su soporte primer nivel del área de Tecnología de la Información.', 'danger');
			this.loading = false;
			this.cdr.detectChanges()
			//this.errorService.getErrorMessage(error)
		})
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
}
