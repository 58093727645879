import { ActivatedRoute } from '@angular/router';
// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap, first } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthenticationService } from '../../SIDERPERU/services/Authentication/_services';
import { ErrorManagementService } from '../../SIDERPERU/services/error-management.service';
import { LocationService } from '../../SIDERPERU/services/Location/location.service';
import { IpServiceService } from '../../SIDERPERU/services/IpAddress/ip-service.service';
import { MatStepper } from '@angular/material';
import { AuthNoticeService } from '../../../../core/auth';
import { ToastrService } from 'ngx-toastr';

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	EMAIL: 'admin@demo.com',
	PASSWORD: 'demo'
};

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	@ViewChild('stepper') stepper: MatStepper;
	
	hide = false //password hide, text 
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	public latitude: string;
	public longitude: string;
	public ipaddress: string;
	loginForm: FormGroup;
	loading = false;
	submitted = false;
	returnUrl: string;
	error = '';

	user: any = null 
	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private authenticationService: AuthenticationService,
		private formBuilder: FormBuilder,
		private cdr: ChangeDetectorRef,
		private errorService: ErrorManagementService,
		public locationService: LocationService,
		private ip: IpServiceService,
		private authNoticeService: AuthNoticeService,
		private toastr: ToastrService

	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required]
		});

		// get return url from route parameters or default to '/'
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

		//this.returnUrl = '/default/tmp/configuration/administration/dashboard';
	}


	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		/* 	// demo message to show
			if (!this.authNoticeService.onNoticeChanged$.getValue()) {
				const initialNotice = `Escriba un
				<strong>Usuario</strong> y una 
				<strong>Contraseña</strong> para ingresar al sistema.`;
				this.authNoticeService.setNotice(initialNotice, 'info');
			}
	
			this.loginForm = this.fb.group({
				email: [DEMO_PARAMS.EMAIL, Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(320) 
				])
				],
				password: [DEMO_PARAMS.PASSWORD, Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100)
				])
				]
			}); */
	}



	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			email: controls['email'].value,
			password: controls['password'].value
		};
		/* 		this.auth
						 .login(authData.email, authData.password)
						 .pipe(
							 tap(user => {
								 console.log("loginnn",user)
								 if (user) {
									 this.store.dispatch(new Login({authToken: user.accessToken}));
									 this.router.navigateByUrl('/'); // Main page
								 } else {
									 this.authNoticeService.setNotice(this.translate.instant('Datos incorrectos'), 'danger');
								 }
							 }),
							 takeUntil(this.unsubscribe),
							 finalize(() => {
								 this.loading = false;
								 this.cdr.detectChanges();
							 })
						 )
						 .subscribe(); */
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	// convenience getter for easy access to form fields
	get f() { return this.loginForm.controls; }

	onSubmit1() {
		this.submitted = true;

		console.log('Estos son los datos que estoy enviando: ', this.f)
		// stop here if form is invalid https://digital.siderperu.pe/api/accounts/microsoft/jarol_ccis@hotmail.com/
		

		this.loading = true;

		let f = 0;
		this.authenticationService.loginSider(this.f.username.value, this.f.password.value).pipe(first()).subscribe(data => {
			//this.router.navigate([this.returnUrl]);
			console.log("urlSider", data.auth_url);
			if (f == 0) {
				window.location.href = data.auth_url;
				//window.open(data.auth_url,"_parent");//_blank 
			} else {

				this.authenticationService.login(this.f.username.value, this.f.password.value).pipe(first()).subscribe(data => {

					/*
					this.locationService.getPosition().then(pos => {
						this.ip.getIPAddress().subscribe((res:any)=>{  
							this.ipaddress=res.ip; 
							this.latitude = pos.lat;
							this.longitude = pos.lng;		
							let send = {
								user: ,
								latitude: this.filter.dateRange.end.toISOString().split('T')[0],
								longitude:this.filter.provider,
								IP=ip,
								PERMISSION:
								}
						});
						},
						err =>{
							console.log('1+'+err)
						} 
					);
			
					*/





					//		this.authenticationService.saveLoginInfo()

					this.router.navigate([this.returnUrl]);
				}, error => {
					// Temporal
					this.router.navigate([this.returnUrl]);
					//this.error = error;
					this.loading = false;
					this.cdr.detectChanges()
					this.errorService.getErrorMessage(error)
				});

				//	this.router.navigate([this.returnUrl]);
			}

			//	window.open(data.auth_url,"_parent");//_blank 
			//this.router.navigate(url);
		});

		// this.authenticationService.login(this.f.username.value, this.f.password.value).pipe(first()).subscribe(data => {
		// 	this.router.navigate([this.returnUrl]);
		// }, error => {
		// 	// Temporal
		// 	this.router.navigate([this.returnUrl]);
		// 	//this.error = error;
		// 	this.loading = false;
		// 	this.cdr.detectChanges()
		// 	this.errorService.getErrorMessage(error)
		// });
	}

	onSubmit() {
		this.authNoticeService.setNotice(null);
		this.loading = true;

		this.authenticationService.login(this.user, this.f.password.value).pipe(first()).subscribe(data => {
			if(data.sider=='true')
			{
				localStorage.setItem("sider","true");
			}
			else{
				localStorage.removeItem("sider");
			}
			this.authenticationService.Permisos(this.f.username.value, this.f.password.value).pipe(first()).subscribe(dataPermisos => {
				
				if (data.auth_url) {
					console.log("USUARIO", data.auth_url);
					window.location.href = data.auth_url;
					//window.open(data.auth_url,"_parent");//_blank 

				} else {
					//this.router.navigate([this.returnUrl]);
					this.locationService.getPosition()
						.then(pos => {
							//this.ip.getIPAddress().subscribe((res:any)=>{  
							this.ipaddress = "0";//res.ip; 
							this.latitude = pos.lat;
							this.longitude = pos.lng;
							let send = {
								user: data.user,
								latitude: this.latitude,
								longitude: this.longitude,
								ip: this.ipaddress,
								permission: 'ok'
							}
							this.authenticationService.saveLoginInfo(send).subscribe(data => {
								this.router.navigate([this.returnUrl]);
								this.loading = false;

							})
							this.loading = false;


						},
							err => {
								console.log(err);
								this.loading = false;


								this.ipaddress = "0"; //res.ip; 
								this.latitude = "0";
								this.longitude = "0";
								let send = {
									user: data.user,
									latitude: this.latitude,
									longitude: this.longitude,
									ip: this.ipaddress,
									permission: 'fail location'
								}
								this.authenticationService.saveLoginInfo(send).subscribe(data => {
									this.router.navigate([this.returnUrl]);
									this.loading = false;

								})
							}
						);


				}
			});

		}, error => {
			// Temporal
			this.authNoticeService.setNotice('Las credenciales proporcionadas no existen', 'danger');
			this.router.navigate([this.returnUrl]);
			//this.error = error;
			this.loading = false;
			this.cdr.detectChanges()
			//this.errorService.getErrorMessage(error)
		}
		);

	}

	getBack(){
		this.authNoticeService.setNotice(null);
		this.stepper.selectedIndex = 0
	}

	accountValidate(){
		this.authNoticeService.setNotice(null);
		this.loading = true;

		let send = {
			username: this.f.username.value
		}
		
		this.authenticationService.accountValidate(send).subscribe(data => {
			
			if(data.response == 'success'){
				if(data.data.active_directory == true){
					this.user = data.data.user
					this.loginForm.get('password').setValue("terminal")
					this.onSubmit()
				}else{
					this.user = data.data.user
					this.stepper.selectedIndex = 1
				}
				this.loading = false;
				this.cdr.detectChanges()
			}else if(data.response == 'error'){
				this.authNoticeService.setNotice('Usuario o correo electrónico no existe', 'danger');
				this.loading = false;
				this.cdr.detectChanges()
			}
		},
		error => {
			this.authNoticeService.setNotice('Se ha perdido la conexión con los servidores y/o servicios. Intente nuevamente. Contacte a su soporte primer nivel del área de Tecnología de la Información.', 'danger');
			this.loading = false;
			this.cdr.detectChanges()
			//this.errorService.getErrorMessage(error)
		})
	}
}
