// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	openRouteKey: '5b3ce3597851110001cf624810e44c41ed144ebf81233fd40a8168a5', //mapbox
	//domain: "https://digital.siderperu.pe/"
	//domain: "https://10.140.32.124/"
	//domain: "https://10.140.81.135:4443/"
	domain: "https://digitalqa.siderperu.pe:4443/",
	//domain: "https://digitalqa.siderperu.pe:4443/", //QAS
	domainGPS: "https://digital-gps.siderperu.pe/",
	syncPaperless: {
		domain: 'https://apiconsultadoctmpsider.azurewebsites.net',
		servicio: '/api/onlinerecoveryrec',
		Ruc: '20402885549',
		Login: 'admin_sider',
		Clave: 'abc123',
		//RucEmisor: '20538191176',
		TipoDoc: '01',
		//Folio: 'F001-55145',
		TipoRetorno: '3'
	}


};
