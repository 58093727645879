import { environment } from './../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DomainService {


  //private domain: string ="http://34.216.148.9:8080/"
  //private domain: string ="http://34.216.148.9:443/"
  //private domain: string ="http://52.116.35.77:443/"
  //private domain: string = "http://127.0.0.1:8000/"

  private domain: string = "https://10.140.32.124:443/"

  //private domain: string = "http://52.116.35.78:443/"

  private domainProyect: string = "http://localhost:4200/default/"
  constructor(private router: Router) {

  }

  getDomain(): string {
    return environment.domain
  }
  getDomain2(): string {
    return environment.domainGPS
  }

  getDomainProyect(): string {

    var urlActualCompleta = '' + window.location.href
    var rutaActualComponente = '' + this.router.url

    this.domainProyect = urlActualCompleta.replace(rutaActualComponente, '') + '/default/tmp/'

    return this.domainProyect
  }

}
