import { AuthenticationService } from './../../../../pages/SIDERPERU/services/Authentication/_services/authentication.service';
// Angular
import { Component, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
//import { type } from 'os';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<any>;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>, private authService: AuthenticationService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	usuario:any;
	ngOnInit(): void {
		//this.user$ = JSON.parse(localStorage.getItem("currentUser"));
		//console.log("userss", this.user$)
		var permisos = JSON.parse(localStorage.getItem("Permisos"));
		if(typeof permisos!='undefined'){
			this.usuario = permisos.firstname;
		}
		else{
			this.usuario = typeUser.user
		}

		var typeUser = JSON.parse(localStorage.getItem('currentUser'));
		this.user$ = JSON.parse(localStorage.getItem("currentUser"));
		

		console.log("userss", this.user$)
		console.log("userss", this.usuario)

	}

	/**
	 * Log out
	 */
	logout() {
		//this.store.dispatch(new Logout());
		localStorage.clear()
		this.authService.logout()

	}
}
