import { ConfigurationService } from './views/pages/SIDERPERU/services/Configuration/configuration.service';
import { Subscription } from 'rxjs';
// Angular
import { Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService } from './core/_base/layout';
// Metronic
import { TranslationService } from './core/_base/metronic';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as chLang } from './core/_config/i18n/ch';
import { locale as esLang } from './core/_config/i18n/es';
import { locale as jpLang } from './core/_config/i18n/jp';
import { locale as deLang } from './core/_config/i18n/de';
import { locale as frLang } from './core/_config/i18n/fr';

import { DomainService } from './views/pages/services/domain.service';

import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { AuthenticationService } from './views/pages/SIDERPERU/services/Authentication/_services/authentication.service';

import { ErrorManagementService } from './views/pages/SIDERPERU/services/error-management.service';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
	// Public properties
	title = 'Metronic';
	loader: boolean;
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(private translationService: TranslationService,
		private router: Router,
		private layoutConfigService: LayoutConfigService,
		private configModuleService: ConfigurationService,
		private splashScreenService: SplashScreenService,
		private dom: DomainService,
		private http: HttpClient,
		private authService: AuthenticationService,
		private errorService: ErrorManagementService,
		) {

		// register translations
		this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	 headers: HttpHeaders = new HttpHeaders({
		'Content-Type': 'application/json'
		//Authorization: this.authService.getToken()
	  })

	ngOnInit(): void {
		let urlArrive=window.location.href;
		let resultSearch=urlArrive.search('#');
		if(resultSearch>=0){
			let partsUrl=urlArrive.split('#');
			let domainPart=partsUrl[0];
			if(domainPart.search('authorized')>=0){
				let urlValid=this.dom.getDomain()+'api/accounts/authorized/?'+partsUrl[1];
    			this.http.get(urlValid,{ headers: this.headers }).subscribe((data: any) => {
					console.log(data)
					let response:any={};
					response=data;
					if(response.response!='true'){
						console.log('Credenciales no validas.1')
						this.errorService.getErrorMessage({'error':'Credenciales no validas.'})
						localStorage.clear()
						this.router.navigate(['/auth/login']);
					}
					else{
						console.log('validas.2')
						localStorage.removeItem("sider");
						this.router.navigate(['/']);
					}
				  },
					error => {

				  })
			}
		}

		
        console.log(this.router.url);
		// enable/disable loader
		this.loader = this.layoutConfigService.getConfig('loader.enabled');

		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				this.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);
			}
		});
		this.unsubscribe.push(routerSubscription);

		//TODO: ENVIO 1 POR DEFECTO, DESPUES SE CONFIGURA
		this.configModuleService.getConfigurationModulo(1)

	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}
}
