// Angular
import { Injectable } from '@angular/core';
// RxJS
import { Subject } from 'rxjs';

@Injectable()
export class MenuConfigService {
	// Public properties
	onConfigUpdated$: Subject<any>;
	// Private properties
	private menuConfig: any;

	/**
	 * Service Constructor
	 */
	constructor() {
		// register on config changed event and set default config
		this.onConfigUpdated$ = new Subject();
	}

	/**
	 * Returns the menuConfig
	 */
	getMenus() {
		return this.menuConfig;
	}

	/**
	 * Load config
	 *
	 * @param config: any
	 */

	ValidPermison(per: any) {
		var flag = false;
		console.log("MENU", per);
		var permisos = JSON.parse(localStorage.getItem("Permisos"));
		if (permisos.content_types) {
			permisos.content_types.forEach((element) => {
				if (element.model.includes(per)) {
					console.log("ValidPer", per);
					flag = true;
				}
			});
		}

		return flag;
	}



	loadConfigs(config: any) {
	var 	flagAgp1= false;
	var	flagAgp2= false;
	var	flagAgp3= false;
	var	flagAgp4= false;
		var permisos = JSON.parse(localStorage.getItem("Permisos"));

		var typeUser = JSON.parse(localStorage.getItem("currentUser"));

		if (typeUser.user == "admin" || typeUser.user == "sideradmin") {
		}
		else{
			console.log("MENUUU", config.aside.items);
			

			config.aside.items.forEach((element1) => {
				//PRIMER NIVEL

				
				console.log("Primernivel");
				if (element1.permission) {
					let flagPer = this.ValidPermison(element1.permission);

					if (flagPer == true) {
						element1.permission = null;
						flagAgp1 = true;
					}
				}

				

				if (element1.submenu) {
					element1.submenu.forEach((element2) => {
						//SEGUNDO NIVEL
						console.log("Segundonivel");

						if (element2.permission) {
							let flagPer = this.ValidPermison(
								element2.permission
							);

							if (flagPer == true) {
								element2.permission = null;
								flagAgp2 = true;
							}
						}
				

						if (element2.submenu) {
							element2.submenu.forEach((element3) => {
								//TERCER NIVEL
								console.log("Tercernivel");
								if (element3.permission) {
									let flagPer = this.ValidPermison(
										element3.permission
									);

									if (flagPer == true) {
										element3.permission = null;
										flagAgp3 = true;
									}
								}
					

								if (element3.submenu) {
									element3.submenu.forEach((element4) => {
										console.log("cUARTOniVEL");
										if (element4.permission) {
											let flagPer = this.ValidPermison(
												element4.permission
											);

											if (flagPer == true) {
												element4.permission = null;
												flagAgp4 = true;
											}
										}

										if(flagAgp4){
											element1.permission=null;
											
										
											element2.permission=null;
												
											element3.permission=null;
											element4.permission=null;
												
								
											flagAgp4=false;
										
									
										}
				
									});
							
								}

								
						if(flagAgp3||flagAgp4){
							element1.permission=null;
							
						
							element2.permission=null;
								
							element3.permission=null;
								
						
							flagAgp3=false;
							flagAgp4=false;
						
					
						}
				
							});

					
						}

			
						if(flagAgp2||flagAgp3||flagAgp4){
							element1.permission=null;
							
						
							element2.permission=null;
								
							flagAgp2=false;
							flagAgp3=false;
							flagAgp4=false;
						
					
						}
		
					});

						

				}
		
if(flagAgp1||flagAgp2||flagAgp3||flagAgp4){
	element1.permission=null;

	flagAgp1=false;
	flagAgp2=false;
	flagAgp3=false;
	flagAgp4=false;

}
			
			
			
			
			});

		}

		//	config.aside.items=ArrS1;

		//	console.log("ARRAYS",ArrS1);

		this.menuConfig = config;
		this.onConfigUpdated$.next(this.menuConfig);
	}
}
